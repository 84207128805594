import React from "react";
import Image from "gatsby-image";
import { graphql, Link } from "gatsby";
import { Button, Container, Row, Col } from "reactstrap";
import Layout from "../components/layout";
import Share from "../components/share";
import Style from "./artist.module.css";
import SEO from "../components/SEO";
import Social from "../components/social";
import Player from "../components/mediaplayer";
import PropTypes from "prop-types";

export default class ArtistTemplate extends React.Component {
  static propTypes = {
    color: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.onContactButtonClick = this.onContactButtonClick.bind(this);
    this.onPressButtonClick = this.onPressButtonClick.bind(this);
  }

  onContactButtonClick() {
    window.location.href = `mailto:booking@eingang.co?subject=Eingang Bookings / ${this.props.data.post.frontmatter.name} booking request`;
  }

  onPressButtonClick() {
    window.location.href = this.props.data.post.frontmatter.presskit.publicURL;
  }

  render() {
    return (
      <Layout imageData={this.props.data.imageData.childImageSharp.fluid}>
        {/* SEO Component */}
        <SEO
          title={this.props.data.post.frontmatter.name}
          description={
            this.props.data.post.frontmatter.description ||
            this.props.data.post.excerpt
          }
          keywords={this.props.data.post.frontmatter.tags}
          image={
            this.props.data.post.frontmatter.featureImage.image.childImageSharp
              .fixed.src
          }
          pathname={this.props.data.post.fields.slug}
          article
        />
        {/* Modify container to page */}
        <Container>
          <Row>
            <Col>
              <Link className={Style.homeLink} to="/">
                Back to frontpage
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1 className="py-4">{this.props.data.post.frontmatter.name}</h1>
            </Col>
          </Row>
          <Row>
            <Col sm="12" lg="6">
              <Image
                fluid={
                  this.props.data.post.frontmatter.featureImage.image
                    .childImageSharp.fluid
                }
              />
              <div className="pt-3">
                <Button
                  onClick={this.onContactButtonClick}
                  color="primary"
                  outline={true}
                  block={true}
                  className={Style.submitButton}
                >
                  {this.props.data.post.frontmatter.sex === `Female`
                    ? `Book her`
                    : `Book him`}
                </Button>
              </div>
              <div className="pt-3">
                <Button
                  onClick={this.onPressButtonClick}
                  color="primary"
                  outline={true}
                  block={true}
                  className={Style.submitButton}
                >
                  Download{` `}
                  {this.props.data.post.frontmatter.sex === `Female`
                    ? `her press kit`
                    : `his press kit`}
                </Button>
              </div>
              <div className="py-4">
                <Player frame={this.props.data.post.frontmatter.frame} />
              </div>
              <div className="py-4">
                {this.props.data.post.frontmatter.social && (
                  <Social
                    links={this.props.data.post.frontmatter.social}
                    gender={this.props.data.post.frontmatter.sex}
                  />
                )}
              </div>
            </Col>
            <Col sm="12" lg="6">
              <div
                dangerouslySetInnerHTML={{ __html: this.props.data.post.html }}
              />
              <div className="py-4">
                <Share
                  socialConfig={{
                    twitterHandle: this.props.data.infos.siteMetadata
                      .twitterHandle,
                    config: {
                      url: `${this.props.data.infos.siteMetadata.url}${this.props.data.post.fields.slug}`,
                      title: `Eingang Booking / ${this.props.data.post.frontmatter.name}`
                    }
                  }}
                  tags={this.props.data.post.frontmatter.tags}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query($slug: String!, $backgroundPath: String!) {
    imageData: file(
      sourceInstanceName: { eq: "assets" }
      relativePath: { eq: $backgroundPath }
    ) {
      id
      relativePath
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      excerpt
      fields {
        slug
      }
      frontmatter {
        name
        email
        tags
        sex
        description
        social {
          network
          url
        }
        presskit {
          publicURL
        }
        color
        frame
        date(formatString: "DD MMMM, YYYY")
        featureImage {
          image {
            childImageSharp {
              fixed(width: 750, height: 750) {
                ...GatsbyImageSharpFixed_withWebp
              }
              fluid(maxWidth: 650, maxHeight: 650) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    metadata: site {
      siteMetadata {
        bookingEmail
        siteUrl
      }
    }
    infos: site {
      ...ShareInformation
    }
  }
`;
