import React from "react";
// import PropTypes from "prop-types";
import "@fortawesome/react-fontawesome";
import { SocialIcon } from "react-social-icons";
import { Button } from "reactstrap";

import { Row, Col } from "reactstrap";

import "./social.scss";

class Social extends React.Component {
  constructor(props) {
    super(props);
    this.gender = props.gender === `Female` ? `her` : `him`;
    this.links = props.links;
  }

  render() {
    let content = this.links.map(item => (
      <Button key={item.network}>
        <SocialIcon
          network={item.network.toLowerCase()}
          url={item.url.toLowerCase()}
          style={{ height: 35, width: 35 }}
        />
      </Button>
    ));

    return (
      <Row className="post-social">
        <Col>
          <h1 className="pb-3">Find {this.gender.toString()} on</h1>
          {content}
        </Col>
      </Row>
    );
  }
}

// Social.propTypes = {
//   links: PropTypes.shape().isRequired
// };

export default Social;
